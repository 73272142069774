import * as crypto from 'crypto';

export const CONTEN_SECURITY_POLICY = 'Content-Security-Policy';
export const X_NONCE = 'x-nonce';

export const nonce = Buffer.from(
    crypto.randomBytes(16).toString('hex'),
).toString('base64');

const scriptsSrcEval = [
    'https://www.google.com',
    'https://www.gstatic.com',
    'https://cdn.moengage.com',
    'https://www.google-analytics.com',
    'https://maps.googleapis.com',
    'https://googletagmanager.com',
    'https://www.googletagmanager.com',
    'https://facebook.com',
    'https://connect.facebook.net',
    'https://twitter.com',
    'https://x.com',
    'https://parentarea.co',
    'https://api.bigdatacloud.net',
    'https://trustpilot.com',
    'https://uk.trustpilot',
    'https://franchieswmf.com',
    'https://free.wemakefootballers.com',
    'https://performance.wemakefootballers.com',
    'https://kitlocker.com',
    'https://florida.wemakefootballers.com',
    'https://wemakefootballers.com',
    'https://schema.org',
    'https://widget.trustpilot.com',
    "https://wati-integration-prod-service.clare.ai",
    'https://static.hotjar.com',
    'https://script.hotjar.com',
    'wss://ws.hotjar.com',
    'https://ajax.cloudflare.com',
].join(' ');

const cspHeader = `
    default-src 'self' https:;
    base-uri 'self' 'unsafe-inline';
    script-src 'self' 'unsafe-eval' 'unsafe-inline' ${scriptsSrcEval};
    style-src 'self' 'unsafe-inline' https:;
    img-src 'self' 'unsafe-inline' blob: data: https:;
    font-src 'self' 'unsafe-inline' https:;
    object-src 'none';
    form-action 'self' 'unsafe-inline';
    frame-ancestors 'none';
    connect-src 'self' https: wss://ws.hotjar.com;
    worker-src 'self' blob:;
    upgrade-insecure-requests;
`;

export const sanitizedCSPHeader = cspHeader.replace(/\s{2,}/g, ' ').trim();
